import React from 'react'
import Text from 'components/typograpgy/Text'

function Downloads() {
  return (
    <React.Fragment>
      <Text>
        Annual Reports
    </Text>
      <Text>
        Proposals
  </Text>
      <Text>
        Approvals
  </Text>
      <Text>
        .................
    </Text>
    </React.Fragment>
  )
}

export default Downloads