import React from 'react'
import { Container, Media } from 'reactstrap'
import { createUseStyles } from 'react-jss'

import Card from 'components/base/Card'
import ExecutiveMembers from 'components/lists/ExecutiveMembers'
import Spacer from 'components/base/Spacer'
import Text from 'components/typograpgy/Text'
import PaymentButtonSection from 'components/PaymentButtonSection'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'baseline',
    height: '200px'
  },
  educators: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'baseline',
    height: '800px'
  },
  image: {
    width: 200,
    borderRadius: 4,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    marginRight: 10
  }
})

function ExecutiveTeam() {
  const classes = useStyles()
  const isSmallScreen = window.innerWidth <= 768

  const teamMembers = ExecutiveMembers.map((teamMember) => (
    <Card details={teamMember} memberdetails={ExecutiveMembers} />
  ))

  const educators = ExecutiveMembers.map((teamMember) => (
    <Card details={teamMember} memberdetails={ExecutiveMembers} />
  ))
  return (
    <>
      <PaymentButtonSection text="90% of the human brain develops before the age of 5. The time lost for children without ECE will never be recovered"/>
      <Container fluid>
        <Spacer height="30" />
        <Text size="xxlarge" bold align="center" density="normal">Executive Team</Text>
        <div className={classes.container}>
          {teamMembers}
        </div>
        <Container>
          <Spacer height="30" />
          <Text size="xxlarge" bold align="center" density="normal">Our Educators</Text>
          <div className={classes.educators}>
            <hr />
            <Spacer height={10} />
            {isSmallScreen && <img className={classes.image} object src="../images/team/priti.jpeg" alt="priti" />}
            <Media style={{alignItems : "center"}}>
              <Media left top>
                {!isSmallScreen && <Media className={classes.image} object src="../images/team/priti.jpeg" alt="priti" />}
              </Media>
              <Media body>
                <Media heading>
                  <Text size="xlarge" bold align="left" density="normal">Priti khor</Text>
                </Media>
                <Text align="justify">Priti is an Educator in the Sanganeri Gate community. After completing her Bachelors in Arts, she pursued a career in teaching. She has eight years of teaching experience at both primary and secondary school level. She likes Yoga and Cooking.</Text>
                <Text align="justify">In Shiksha Ki Ore she likes conducting outdoor games as well as art and craft activities.</Text>
              </Media>
            </Media>
            <hr />
            <Spacer height={10} />
            {isSmallScreen && <img className={classes.image} object src="../images/team/suman.jpeg" alt="suman" />}
            <Media style={{alignItems : "center"}}>
              <Media left top>
                {!isSmallScreen && <Media className={classes.image} object src="../images/team/suman.jpeg" alt="suman" />}
              </Media>
              <Media body>
                <Media heading>
                  <Text size="xlarge" bold align="left" density="normal">Suman Mahavaar</Text>
                </Media>
                <Text align="justify">Suman is an Educator at Transport Nager. She has nine years of teaching experience at both primary and secondary school level. After completing her Masters in Arts degree from Rajasthan University, she pursued a career in teaching, which is what she has always been passionate about. She enjoys cooking and reading in her spare time. </Text>
                <Text align="justify">In Shiksha Ki Ore she enjoys organizing and managing skits and role play sessions with the children.</Text>
              </Media>
            </Media>
            <hr />
            <Spacer height={10} />
            {isSmallScreen && <img className={classes.image} object src="../images/team/sumanB.jpeg" alt="sumanB" />}
            <Media style={{alignItems : "center"}}>
              <Media left top>
                {!isSmallScreen && <Media className={classes.image} object src="../images/team/sumanB.jpeg" alt="sumanB" />}
              </Media>
              <Media body>
                <Media heading>
                  <Text size="xlarge" bold align="left" density="normal">Suman Basetiya</Text>
                </Media>
                <Text align="justify">Suman is an Educator at Calgary1. She has two years of teaching experience at both primary and secondary school level. She is currently also pursuing a Masters of Arts degree in addition to her role of Educator at Shiksha Ki Ore Program. In her free time she enjoys dancing and singing. </Text>
                <Text align="justify">In her role at Shiksha Ki Ore, she likes teaching English poems the most.</Text>
              </Media>
            </Media>
            <hr />
            <Spacer height={10} />
            {isSmallScreen && <img className={classes.image} object src="../images/team/meenu.jpeg" alt="meenu" />}
            <Media style={{alignItems : "center"}}>
              <Media left top>
                {!isSmallScreen && <Media className={classes.image} object src="../images/team/meenu.jpeg" alt="meenu" />}
              </Media>
              <Media body>
                <Media heading>
                  <Text size="xlarge" bold align="left" density="normal">Meenu Nirwan</Text>
                </Media>
                <Text align="justify">Meenu is an Educator in the Kathputli Nagar community. She also takes tution classes in the community. She is an undergraduate and is currently pursuing a Masters of Arts degree. She likes to make comedy videos as well as sing. </Text>
                <Text align="justify">In the Shiksha Ki Ore program she enjoys organizing the extra curricular activity sessions with children.</Text>
              </Media>
            </Media>
            <hr />
            <Spacer height={10} />
            {isSmallScreen && <img className={classes.image} object src="../images/team/swati.jpeg" alt="swati" />}
            <Media style={{alignItems : "center"}}>
              <Media left top>
                {!isSmallScreen && <Media className={classes.image} object src="../images/team/swati.jpeg" alt="swati" />}
              </Media>
              <Media body>
                <Media heading>
                  <Text size="xlarge" bold align="left" density="normal">Swati Sain</Text>
                </Media>
                <Text align="justify">Swati is an Educator at Jhalana. She has prior teaching experience and has a BA degree from Rajasthan University. Outside of work she likes to travel to historical places and temples.</Text>
                <Text align="justify">In Shiksha Ki Ore, she enjoys the part of engaging and sensitising families in the communities on the importance of Early Childhood Education and also likes to spend time in the community to learn more about it and its residents.</Text>
              </Media>
            </Media>
            <hr />
            <Spacer height={10} />
            {isSmallScreen && <img className={classes.image} object src="../images/team/deepika.jpeg" alt="deepika" />}
            <Media style={{alignItems : "center"}}>
              <Media left top>
                {!isSmallScreen && <Media className={classes.image} object src="../images/team/deepika.jpeg" alt="deepika" />}
              </Media>
              <Media body>
                <Media heading>
                  <Text size="xlarge" bold align="left" density="normal">Deepika Bairwa</Text>
                </Media>
                <Text align="justify">Deepika is an Educator at Calgary2. She has a year of teaching experience and has a Bachelors of Arts degree. Whenever possible, she tries to pursue her passion for travel.</Text>
                <Text align="justify">At the Shiksha Ki Ore program she loves interacting and working with little kids.</Text>
              </Media>
            </Media>
          </div>
        </Container>
        <Spacer height={900}/>
      </Container>
    </>
  )
}

export default ExecutiveTeam
