import React, { useState } from 'react'

import { createUseStyles } from 'react-jss'
import { Container } from 'reactstrap'
import TeamFifty from 'components/lists/TeamFifty'
import Spacer from 'components/base/Spacer'
import Button from 'components/base/Button'
import Card from 'components/base/Card'
import Text from 'components/typograpgy/Text'
import PaymentButtonSection from 'components/PaymentButtonSection'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left'
  },
  buttons: {
    textAlign: 'center'
  },
  frame: {
    textAlign: 'center'
  }
})

function Team50() {
  const isSmallScreen = window.innerWidth <= 768
  const classes = useStyles()

  const [selectedCategory, setSelectedCategory] = useState('Our Team')
  const [teamHidden, SetTeamHidden] = useState(true)

  const categories = [
    'Our Team',
    'Education Policy',
    'Curriculum Development',
    'App Development',
    'Website and Social Media',
    'Child Educator Mentorship',
    'Management'
  ]

  const selectedTeam = selectedCategory === 'Our Team' ? TeamFifty : TeamFifty.filter((member) => member.team === selectedCategory)

  const teamFiftymembers = selectedTeam.map((member) => (
    <Card details={member} memberdetails={TeamFifty} />
  ))

  const buttons = categories.map((category) => (
    <Button
      label={category}
      onClick={() => setSelectedCategory(category)}
    />
  ))

  const Members = () => {
    return (
      <Container>
        <Text size="xxlarge" bold align="center" density="normal"> Team 50! </Text>
        <Text align="center">A group of individuals supporting the cause of early childhood education with their time, money, energy and intellect</Text>
        <Spacer height={20} />
        <div className={classes.buttons}>
          {buttons}
        </div>
        <Spacer height={20} />
        <div className={classes.container}>
          {teamFiftymembers}
        </div>
      </Container>
    )
  }

  const Activities = () => {
    return (
      <Container>
        <Text size="xxlarge" bold align="center" density="normal">Group engagement updates</Text>
        <Spacer height={20} />
        <Text align="Center" size="xlarge">Newsletter(September) 2020</Text>
        <Spacer height={20} />
        <div className={classes.frame}>
          <iframe title="newsletter" src="https://drive.google.com/file/d/1KwBocVLe1xPAvSpiS34tV9Tkr_CEJx-H/preview" width={isSmallScreen ? "auto" : "640"} height="700"></iframe>
        </div>
        <Spacer height={20} />
      </Container>
    )
  }

  return (
    <React.Fragment>
      <PaymentButtonSection text="90% of the human brain develops before the age of 5. The time lost for children without ECE will never be recovered"/>
      <Spacer height={30} />
      <div className={classes.buttons}>
        <Button
          label="Members"
          onClick={() => SetTeamHidden(true)}
        />
        <Button
          label="Contribution"
          onClick={() => SetTeamHidden(false)}
        />
      </div>
      {teamHidden ? Members() : Activities()}
    </React.Fragment>
  )
}

export default Team50
