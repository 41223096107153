import React, { Component } from 'react';
class PaymentButton extends Component { 
    formRef = React.createRef();
    componentDidMount() {
        const script = document.createElement('script');
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        script.async = true;
        script.setAttribute('data-payment_button_id', this.props.payment_button_id);
        this.formRef.current.appendChild(script);
    }
    render() {
        return (
            <form ref={this.formRef}></form>
        )
    }
}
export default PaymentButton;