import React from 'react'
import {Container, Media} from 'reactstrap'
import { createUseStyles } from 'react-jss'


import Spacer from 'components/base/Spacer'
import Text from 'components/typograpgy/Text'
import PaymentButtonSection from 'components/PaymentButtonSection'

const useStyles = createUseStyles({
  image: {
    width: 200,
    borderRadius: 4,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    marginRight: 10
  }
})

function AdvisoryBoard() {
  const isSmallScreen = window.innerWidth <= 768
  const classes = useStyles({isSmallScreen})

  return (
    <>
      <PaymentButtonSection text="90% of the human brain develops before the age of 5. The time lost for children without ECE will never be recovered"/>
      <Container>
        <Spacer height={30} />
        <Text size="xxlarge" bold align="center" density="normal">Advisory Board Members</Text>
        <Spacer height={30} />
        {isSmallScreen && <img className={classes.image} object src="../images/board/kritika.jpeg" alt="kritika" />}
        <Media style={{alignItems : "center"}}>
          <Media left top>
            {!isSmallScreen && <Media className={classes.image} object src="../images/board/kritika.jpeg" alt="Kritika" />}
          </Media>
          <Media body>
            <Media heading>
              <Text size="xlarge" bold align="left" density="normal">Kritika Sangani</Text>
            </Media>
            <Text align="justify">Kritika is currently working as Chief Executive Officer at TAP India Foundation which works on reintegration and dropout prevention of Out of School Children. She has spent the last 5 years at Indus Action in the capacity of Associate Director, Operations where she supervised the scaling of the School Readiness Program pilot from 1 to 6 states, managed RTE campaign operations across 5 states and also led the Talent and Finance functions. </Text>
            <Text align="justify">She has 10+ years of experience in the Development and the Corporate sectors and feels passionately about solving complex social problems through the paradigm of Human Centred Design. She made her foray into the Development sector through the Teach For India Fellowship program.</Text>
            <Text align="justify">In the early years of her career, she catered to the Investment Banking Technology space, working with a London-based multi-national, as Associate Sales Team Manager, South India.</Text>
            <Text align="justify">She enjoys travelling, reading, the art/science of "tidying", brushing up on Physics and Biology fundamentals whenever she gets a chance and collecting Urdu and Sanskrit couplets.</Text>
          </Media>
        </Media>
        <hr />
        <Spacer height={10} />
        {isSmallScreen && <img className={classes.image} object src="../images/board/ravish.jpeg" alt="ravish" />}
        <Media style={{alignItems : "center"}}>
          <Media left top>
            {!isSmallScreen && <Media className={classes.image} object src="../images/board/ravish.jpeg" alt="ravish" />}
          </Media>
          <Media body>
            <Media heading>
              <Text size="xlarge" bold align="left" density="normal">Ravish Bhimani</Text>
            </Media>
            <Text align="justify">Ravish is the CFO at Shree Gajanan Industries.He is a seasoned finance professional with a demonstrated history in the food production industry, having expertise in negotiation, business planning, managerial finance, and business development and accounting.</Text>
            <Text align="justify">Ravish is also a Founding member of TiE Nizamabad Chapter, which was formed with the help of Kakatiya Sandbox, an initiative to create and strengthen the eco-system for aspiring entrepreneurs as well as for second generation entrepreneurs. He is on the executive committee at Kakatiya Sandbox.</Text>
            <Text align="justify">Ravish holds an EMBA with a concentration in Cost and Finance from S P Jain, Dubai. While pursuing his Masters, he worked with a Jebel Ali Based Company, Kimoha Entrepreneurs Limited as an analyst covering Cost, System, Production, Administrative and Marketing analysis. </Text>
            <Text align="justify">In his spare time, he loves to play badminton, cricket as well as explore wildlife.</Text>
          </Media>
        </Media>
        <hr />
        <Spacer height={10} />
        {isSmallScreen && <img className={classes.image} object src="../images/board/chris.jpeg" alt="chris" />}
        <Media style={{alignItems : "center"}}>
          <Media left top>
            {!isSmallScreen && <Media className={classes.image} object src="../images/board/chris.jpeg" alt="chris" />}
          </Media>
          <Media body>
            <Media heading>
              <Text size="xlarge" bold align="left" density="normal">Chris Kollian</Text>
            </Media>
            <Text align="justify">Chris is a Lead in the operations vertical of Indus Action. She expertise’s in Early Childhood Education and has been in the development sector for almost 10 years.</Text>
            <Text align="justify">Having started her journey as a volunteer at Make a Difference, four years later she next moved to Teach for India, where she was working closely on the ground on their flagship education program. </Text>
            <Text align="justify">She holds a Masters in Education leadership, with a  specialisation in Early Childhood Education in Finland. </Text>
            <Text align="justify">In her spare time she enjoys travelling, trying new cuisines and cooking.</Text>
            <Text align="justify">A quote that has been an inspiration at every stage of her career:
            <Spacer height={5} />
              <i>
                “If you can’t fly, you run. If you can’t run, you walk and if you can’t walk, you crawl, but whatever you do, keep moving forward”
              </i>
            </Text>
          </Media>
        </Media>
        <hr />
        <Spacer height={10} />
        {isSmallScreen && <img className={classes.image} object src="../images/board/mayur.jpeg" alt="mayur" />}
        <Media style={{alignItems : "center"}}>
          <Media left top>
            {!isSmallScreen && <Media className={classes.image} object src="../images/board/mayur.jpeg" alt="mayur" />}
          </Media>
          <Media body>
            <Media heading>
              <Text size="xlarge" bold align="left" density="normal">Mayur Patnala</Text>
            </Media>
            <Text align="justify">Mayur is a Social Worker and Social Entrepreneur, currently serving as Chief Functionary at Nirmaan. He has 12 years of full-time experience and 2 years of volunteering experience and brings with him the unique combination of having worked with key stakeholders in CSR sphere – with IBM for 5 years, with Govt. of Andhra Pradesh (United) for 5 years prior to Nirmaan.</Text>
            <Text align="justify">Previously, in his role in Government of Andhra Pradesh as State Mission Manager, Skill Development Mission, he led a team which trained 5,000 economically backward youth and employed 2,500 of them. </Text>
            <Text align="justify">He has completed his B.E.(Hons) in Computer Science & B.E.(Hons) in Mechanical Engineering from BITS Pilani.</Text>
            <Text align="justify">He is passionate about Social Innovation. While studying at BITS, he co-founded Nirmaan Organization, erstwhile ‘My India’. BITS Alumni Association has conferred him with BITSAA 30 under 30 Award in Community Service.</Text>
          </Media>
        </Media>
        <hr />
        <Spacer height={10} />
        {isSmallScreen && <img className={classes.image} object src="../images/team/sridhar.jpg" alt="sridhar" />}
        <Media style={{alignItems : "center"}}>
          <Media left top>
            {!isSmallScreen && <Media className={classes.image} object src="../images/team/sridhar.jpg" alt="sridhar" />}
          </Media>
          <Media body>
            <Media heading>
              <Text size="xlarge" bold align="left" density="normal">Sridhar Neelam</Text>
            </Media>
            <Text align="justify">Sridhar is the Founding Director of Santhusta Foundation. He is also a team member of Pilani Innovation and Entrepreneurship Development Society at BITS Pilani. In the 10+ years of experience in the non profit sector,</Text>
            <Text align="justify">Sridhar was able to design and deliver significant social impact projects both in urban and rural communities. His interests include early childhood education and youth volunteerism. </Text>
          </Media>
        </Media>
      <Spacer height={30} />
      </Container>
    </>
  )
}

export default AdvisoryBoard
