import React from 'react'
import { Container, Media, Card, CardImg, CardSubtitle, CardBody } from 'reactstrap'
import { createUseStyles } from 'react-jss'
import { faBrain, faChild, faHandsHelping } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Carousel from 'components/Carousel'
import Text from 'components/typograpgy/Text'
import Spacer from 'components/base/Spacer'
import ImageText from 'components/ImageText'
import PaymentButtonSection from 'components/PaymentButtonSection'

const useStyles = createUseStyles({
  container: {
    display:({isSmallScreen}) => isSmallScreen ? '' : 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems:'center'
  },
  mediaObject: {
    alignItems: 'center'
  },
  image: {
    width:({isSmallScreen}) => isSmallScreen ? '100%' : '30%',
    height: 'auto',
    alignItems: 'center',
    marginBottom: 10,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  storyImage: {
    width: 300,
    alignItems: 'center'
  },
  text: {
    textAlign: 'justify'
  },
  stats: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  circle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#55a846',
    width: 200,
    height: 200
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 5
  }
})

const items = [
  {
    src: '../images/slideProgram1.png',
    altText: 'Slide 1',
    caption: null,
    // link: '/impactDiaries#4'
  },
  {
    src: '../images/slideProgram3.jpeg',
    altText: 'Slide 2',
    caption: null,
    // link: '/impactDiaries#3'
  },
  {
    src: '../images/slideProgram2.png',
    altText: 'Slide 3',
    caption: null,
    // link: '/impactDiaries#0'
  }
]

const ImpactData = [
  {
    text: 'Attendance in ECE programs',
    number: '38%'
  },
  {
    text: 'Young Children at risk of poor development due to lack of ECE',
    number: '45%'
  },
  {
    text: 'Life Earning potential achieved for individuals who don\'t get ECE',
    number: '39%'
  }
]

const stats = [
  {
    text: 'supported through education initiatives',
    childText: 'Children',
    number: '1600'
  },
  {
    text: 'supported in form of ECE training, resource provisions',
    childText: 'Community Educators',
    number: '72'
  },
  {
    text: 'trained on Early Childhood Education to continue child\'s learning during Covid and lockdown',
    childText: 'Parents/Families',
    number: '135'
  },
  {
    text: 'engaged on community education projects',
    childText: 'Volunteers',
    number: '100+'
  },
  {
    text: 'approved by Department of Women and Child Developement (WCD) to run Shiksha Ki Ore',
    childText: 'MOU',
    number: '1'
  }
]

function Program() {
  const isSmallScreen = window.innerWidth <= 768
  const classes = useStyles({isSmallScreen})

  const data = ImpactData.map((data) => (
    <ImageText number={data.number} text={data.text} color="#ff4141"/>
  ))

  const numbers = stats.map((data) => (
    <ImageText number={data.number} text={data.text} childText={data.childText} impact color="green"/>
  ))

  return (
    <React.Fragment>
      <PaymentButtonSection text="Early Childhood education is one of the best investments a country can make – UNESCO"/>
      <Carousel items={items} />
      <Spacer height={30} />
      <div id="ECE-Stats">
        <Text align="center" bold size="xlarge">India's Early Education Statistics</Text>
      </div>
      <Container>
        <Spacer height={20} />
          <div className={classes.container}>
            {data}
          </div>
        <hr />
        <Spacer height={30} />
        <div id="TheIssue">
          <Text bold size="large" align="justify" isSubHeader>The issue and Santhusta's response</Text>
        </div>
        <Spacer height={30} />
        <Media className={classes.mediaObject}>
          <Media justify href="#">
            <FontAwesomeIcon icon={faBrain} color="#28a745" style={{textAlign: 'center'}} fixedWidth size="6x" />
            &emsp;
          </Media>
          <Media className={classes.text} body>80% of the human brain develops before the age of 3 and 90% develops before the age of 5. Skills developed during early childhood (0-8 years) serve as a multiplier and complement for later skills as an adult.</Media>
        </Media>
        <Spacer height={20}/>
        <Media className={classes.mediaObject}>
          <Media justify href="#">
            <FontAwesomeIcon icon={faChild} color="#28a745" style={{textAlign: 'center'}} fixedWidth size="6x" />&emsp;
          </Media>
          <Media className={classes.text} body>This need can be met by engaging children on tasks involving development of cognitive, creative skills along with Numeracy and Literacy basics. Exposure to these along with socio-emotional support is key to a childs holistic development. At santusta, we work to provide the same to every child we work with.</Media>
        </Media>
        <Spacer height={20}/>
        <Media className={classes.mediaObject}>
          <Media justify href="#">
            <FontAwesomeIcon icon={faHandsHelping} color="#28a745" style={{textAlign: 'center'}} fixedWidth size="6x" />&emsp;
          </Media>
          <Media className={classes.text} body>Santhusta, through Shiksha Ki Ore, works with children aged 3 to 6 of low income communities, helping them gain the critical early childhood skills of Literacy, Numeracy, Socio-Emotional behaviour development as well as focussing on their health and nutrition requirements.</Media>
        </Media>
        <Spacer height={20}/>
        <Text align="justify" size="medium">Santhusta broadly focusses on two aspects:</Text>
        <Text align="justify" size="medium" density="normal">
          &emsp;1. <strong>Early Childhood Education through the Shiksha Ki Ore program</strong> (SKO) supports the current government prescribed and ICDS developed early childhood program that covers literacy, numeracy and cognitive development. SKO's support to the program includes providing tools and resources in form of online content, meeting resource gaps to facilitate teaching and teacher training support to ensure the government prescribed Early Childhood Education Objectives are met by community educators.<br/>
          &emsp;2. <strong>Early Childhood Education Plus or Early Childhood Development through the Centers of Excellence program</strong> Centers of Excellence (CoE) is an ambitious initiative launched by Santhusta to ensure the holistic development of a child. The objectives of this program go far beyond Education to include development aspects of Motor Skills, Social Cognition, Self-esteem and Communication. These are fundamental and core aspects to a child's development, the effects of which follow them into adulthood. Santhusta has chosen 10 local areas (or Anganwadis) of Jaipur to run this program with a view to create model centers of holistic child development that can be integrated into the mainstream.<br/>
        </Text>
        <Spacer height={10} />
        <hr />
        <Spacer height={10} />
        <Text bold size="large" align="center">Remote Learning</Text>
        <Text align="center">Breaking the physical barrier with Shiksha Ki Ore teaching application</Text>
        <hr />
        <Text align="justify" size="small">Santhusta launched Shikska-Ki-Ore, a teaching application, in response to the lockdown during Covid-19. The smartphone solution helps educators take classes remotely getting access to quality, easy to understand teaching methodologies downloaded in a video format on the application.</Text>
        <div className={classes.container}>
          <Card className={classes.image}>
            <CardImg top src="../images/app1.jpeg" alt="Card image cap" />
            <CardBody>
              <CardSubtitle tag="h6" className="mb-2">One view access to entire curriculum</CardSubtitle>
            </CardBody>
          </Card>
          <Card className={classes.image}>
            <CardImg top src="../images/app2.jpeg" alt="Card image cap" />
            <CardBody>
              <CardSubtitle tag="h6" className="mb-2">Teaching methodologies translated in easy to understand video format</CardSubtitle>
            </CardBody>
          </Card>
          <Card className={classes.image}>
            <CardImg top src="../images/app3.jpeg" alt="Card image cap" />
            <CardBody>
              <CardSubtitle tag="h6" className="mb-2">Ability to track progress of each student</CardSubtitle>
            </CardBody>
          </Card>
        </div>
        <Spacer height={10} />
        <hr />
        <Spacer height={10} />
        <Text bold size="large" align="justify" isSubHeader>OUR IMPACT</Text>
        <div className={classes.stats}>
          {numbers}
        </div>
        <hr/>
        <Text align="center" size="normal">
          For more Impact Stories{' '}
          <a href="impactDiaries">
            Click here
          </a>
        </Text>
      </Container>
    </React.Fragment>
  )
}

export default Program