import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { Modal, ModalBody } from 'reactstrap'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Text from 'components/typograpgy/Text'

const useStyles = createUseStyles({
  row: {
    display: ({ isSmallScreen, isStory }) => (isStory && isSmallScreen ? '' : 'flex'),
    margin: '10px'
  },
  name: {
    width: ({ isStory }) => (isStory ? '100%' : 150),
  },
  designation: {
    fontFamily: 'Poppins',
    fontSize: 13,
    fontWeight: 300
  },
  space: {
    borderLeft: '1px solid #3F4473',
    height: '80%',
  }
})

function Card({ details, memberdetails, isStory }) {
  const isSmallScreen = window.innerWidth <= 768
  const classes = useStyles({ isStory, isSmallScreen })

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const circularImage = (
    <img
      src={details.image}
      alt={details.name}
      style={{
        width: isStory ? 300 : 120,
        height: isStory ? 300 : 120,
        border: '1px solid #808080',
        borderRadius: '50%',
        marginRight: isStory ? 30 : 10,
        cursor: 'pointer'
      }}
    />
  )

  const video = (
    <iframe
      title={details.title}
      src={details.video}
      style={{
      width: isStory ? 300 : 120,
      height: isStory ? 300 : 120,
      border: '1px solid #808080',
      borderRadius: '10%',
      marginRight: isStory ? 30 : 10
      }}>
    </iframe>
  )

  return (
    <React.Fragment>
      <div className={classes.row} onClick={details.about ? toggle : ''}>
        { details.image ? circularImage : video }
        {(!isStory && isSmallScreen) && <p className={classes.space} />}
        <div>
          <Text size={isStory ? "xxlarge" : "semiLarge"} align="left" density="compact">
            <p className={classes.name}>{details.name || details.title}</p>
          </Text>
          <Text bold size="small" align="left" density="compact">
            <p className={classes.name}>
              {details.designation || details.date}
            </p>
          </Text>
          {details.company &&
            <Text size="xsmall" align="left" density="tiny">{details.company}</Text>
          }
          {
            details.description &&
            <p style={{
              margin: 10
            }}
              size="xsmall" align="justify" density="tiny">{details.description}</p>
          }
          {details.location &&
            <Text size="xsmall" align="left" density="tiny">{details.location}</Text>
          }
          {memberdetails && memberdetails.filter((member) => details.id === member.id).map((links) =>
            <Text density="compact" align="left">
              {links.linkedIn &&
                <a href={links.linkedIn} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} fixedWidth size="lg" />
                </a>
              }
            </Text>
          )}
        </div>
        <Modal isOpen={modal} toggle={toggle} size="lg" style={{maxWidth: '1600px', width: !isSmallScreen && '80%'}}>
          {/* <ModalHeader toggle={toggle}>{details.name}</ModalHeader> */}
          <ModalBody>
          <Text align="left" size="xlarge">{details.name}</Text>
          <Text align="left">{details.about}</Text>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}

Card.propTypes = {
  isStory: PropTypes.bool
}

Card.defaultProps = {
  isStory: false
}

export default Card
