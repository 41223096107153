import React from 'react'
import { Container, Media } from 'reactstrap'
import { createUseStyles } from 'react-jss'

import Spacer from 'components/base/Spacer'
import Text from 'components/typograpgy/Text'
import PaymentButtonSection from 'components/PaymentButtonSection'

const useStyles = createUseStyles({
  image: {
    width: 200,
    borderRadius: 4,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  mediaObject: {
    alignItems: 'center'
  },
  text: {
    paddingLeft: 20
  }
})

function ChampionsOfCharity() {
  const classes = useStyles()

  return (
    <>
      <PaymentButtonSection text="90% of the human brain develops before the age of 5. The time lost for children without ECE will never be recovered"/>
      <Container>
        <Spacer height={30} />
        <Text size="xxlarge" bold align="center" density="normal">Champions of Charity!</Text>
        <Text align="left">Champions of Charity or CoC is a Santhusta initiative to foster a high-action, high-impact movement to further the critical need of Early Childhood Development. Through the CoC, highly motivated and mission driven individuals or groups commit significant personal wealth, time and/or networks to build on the shared vision of a Happy and Joyful Childhood for all.</Text>
        <Spacer height={30} />
        <Media className={classes.mediaObject}>
          <Media left href="#">
              <Media className={classes.image} object src="../images/charityImage1.jpg" alt="image" />
          </Media>
          <Media className={classes.text} body>This family, based out of Indianapolis, USA and comprising of 20 working professionals with great virtues and generous hearts, has come forward to help the children with their generous contributions. They have joined the Santhusta Champions of Charity initiative.</Media>
        </Media>
        <Spacer height={30} />
        <Media className={classes.mediaObject}>
          <Media left href="#">
              <Media className={classes.image} object src="../images/charity2.png" alt="image" />
          </Media>
          <Media className={classes.text} body>From past 30 years Mr and Mrs Dasharath Reddy Kohir have been providing quality education to the under privileged children. They have a bigger and brighter vision. They wanted to provide their self-less services to more children and hence have joined Santhusta Champions of Charity initiative.</Media>
        </Media>
        <Spacer height={20} />
      </Container>
    </>
  )
}

export default ChampionsOfCharity