import React from 'react'
import { createUseStyles } from 'react-jss'

import Text from 'components/typograpgy/Text'
import Divider from 'components/base/Divider'

const useStyles = createUseStyles({
  square: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:({color}) => color,
    width: 200,
    height: ({impact}) => impact ? 270: 200
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 5
  }
})

function ImageText({ number, childText, text, impact, color}){
  const classes = useStyles({ impact, color })
  return(
    <div className={classes.wrapper}>
      <div className={classes.square}>
        <Text bold size="headline" textColor="#fff">{number}</Text>
        <Divider />
       {childText &&  <Text size="large" textColor="#fff">{childText}</Text>}
        <Text density="normal" size="medium" textColor="#fff">{text}</Text>
      </div>
    </div>
  )
}

export default ImageText