import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, NavLink as RRNavLink } from 'react-router-dom'
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap'

import About from 'components/pages/AboutPage'
import AdvisoryBoard from 'components/pages/AdvisoryBoard'
import ChampionsOfCharity from 'components/pages/ChampionsOfCharity'
import Contact from 'components/pages/ContactPage'
import Downloads from 'components/pages/DownloadPage'
import ExecutiveTeam from 'components/pages/ExecutiveTeam'
import Footer from 'components/pages/Footer'
import HomePage from 'components/pages/HomePage'
import ImpactDiaries from 'components/pages/ImpactDiariesPage'
import Logo from 'components/base/Logo'
import ProgramPage from 'components/pages/ProgramPage'
import Team50 from 'components/pages/Team50'

function App(){
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  return (
    <Router>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">
          <Logo />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className={"ml-auto"} navbar>
            <NavItem><NavLink tag={RRNavLink} activeStyle={{ color: "green"}} exact to="/">Home</NavLink></NavItem>
            <NavItem><NavLink tag={RRNavLink} activeStyle={{ color: "green"}} to="/about">About us</NavLink></NavItem>
            <NavItem><NavLink tag={RRNavLink} activeStyle={{ color: "green"}} to="/project">Program</NavLink></NavItem>
            <NavItem><NavLink tag={RRNavLink} activeStyle={{ color: "green"}} to="/impactDiaries">Impact Diaries</NavLink></NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>Team</DropdownToggle>
              <DropdownMenu right>
                <NavLink tag={RRNavLink} activeStyle={{ color: "green"}} to="/advisory-board"><DropdownItem>Advisory Board</DropdownItem></NavLink>
                <NavLink tag={RRNavLink} activeStyle={{ color: "green"}} to="/Executive-team"><DropdownItem>Executive Team</DropdownItem></NavLink>
                <NavLink tag={RRNavLink} activeStyle={{ color: "green"}} to="/team50"><DropdownItem>Team 50</DropdownItem></NavLink>
                <NavLink tag={RRNavLink} activeStyle={{ color: "green"}} to="/champions-of-charity"> <DropdownItem>Champions of Charity</DropdownItem></NavLink>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem><NavLink tag={RRNavLink} activeStyle={{ color: "green"}} to="/contact">Contact</NavLink></NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <Switch>
        <Route path="/" exact children={<HomePage />} />
        <Route path="/about" children={<About />} />
        <Route path="/advisory-board" children={<AdvisoryBoard />} />
        <Route path="/champions-of-charity" children={<ChampionsOfCharity />} />
        <Route path="/contact" children={<Contact />} />
        <Route path="/download" children={<Downloads />} />
        <Route path="/executive-team" children={<ExecutiveTeam />} />
        <Route path="/impactDiaries" children={<ImpactDiaries />} />
        <Route path="/project" children={<ProgramPage />} />
        <Route path="/team50" children={<Team50 />} />
      </Switch>
      <Footer />
    </Router>
  )
}

export default App