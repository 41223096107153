import React from 'react'
import PropTypes from 'prop-types'

import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    height: 50,
    backgroundColor: '#33A118',
    border: '1px solid #6648b1',
    width: ({ width }) => width,
    marginBottom: 10,
    marginLeft: 10,
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
  },
  label: {
    fontFamily: 'Poppins',
    fontSize: '100%',
    fontWeight: 400,
    color: '#ffffff'
  }
})

function RoundedRectangleButton({ label, onClick, variant, width, href }) {
  const classes = useStyles({ variant, width })

  const buttonProps = href ? { href } : { onClick }

  return (
    <button className={classes.button} type="submit" {...buttonProps}>
      <span className={classes.label}>{label}</span>
    </button>
  )
}

RoundedRectangleButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  variant: PropTypes.oneOf(['normal', 'positive']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

RoundedRectangleButton.defaultProps = {
  onClick: () => null,
  href: null,
  variant: 'normal',
  width: null
}

export default RoundedRectangleButton
