/* eslint-disable max-len */
export default [
  {
    id: 0,
    name: 'Priyatham Katukuri',
    image: '../images/teamfifty/priyatam.jpg',
    designation: 'Analytics Manager',
    company: 'AXA',
    location: 'Paris',
    linkedIn: 'https://www.linkedin.com/in/priyatham-katukuri/',
    team: 'Management'
  },
  {
    id: 1,
    name: 'Harish',
    image: '../images/teamfifty/harish .jpg',
    designation: 'Product Manager',
    company: 'Verizon',
    location: 'New York',
    linkedIn: 'https://www.linkedin.com/in/harish-srigiriraju-32090639/',
    team: 'App Development'
  },
  {
    id: 2,
    name: 'Srikar Goud',
    image: '../images/teamfifty/Srikar.jpg',
    designation: 'PhD, Bioscience',
    company: '',
    location: 'Turku, Finland',
    linkedIn: 'https://www.linkedin.com/',
    team: 'Website and Social Media'
  },
  {
    id: 3,
    name: 'Srujan Kumar',
    image: '../images/teamfifty/srujan.jpg',
    designation: 'Principal Research Associate',
    company: 'Moderna',
    location: 'Boston',
    linkedIn: 'https://www.linkedin.com/in/srujankumargandham/',
    team: null
  },
  {
    id: 4,
    name: 'Varun P',
    image: '../images/teamfifty/varun.jpg',
    designation: 'Head of Fraud Analytics,',
    company: 'PayPal',
    location: 'Hyderabad',
    linkedIn: 'https://www.linkedin.com/',
    team: 'Curriculum Development'
  },
  {
    id: 5,
    name: 'Rajitha Rajashekaran',
    image: '../images/teamfifty/rajitha.jpg',
    designation: 'Database Developer',
    company: 'Lennar',
    location: 'Miami, Florida',
    linkedIn: 'https://www.linkedin.com/in/rajitha-rajashekaran-335250108/',
    team: null
  },
  {
    id: 6,
    name: 'Azhagappan',
    image: '../images/teamfifty/gappan.jpeg',
    designation: 'System Engineer',
    company: 'Delivery Hero',
    location: 'Berlin, Germany',
    linkedIn: 'https://www.linkedin.com/in/gappan28/',
    team: 'Management'
  },
  {
    id: 7,
    name: 'Anudeep',
    image: '../images/teamfifty/anudeep.jpeg',
    designation: 'Research And Development Associate',
    company: 'MassBiologics',
    location: 'Boston, Massachusetts',
    linkedIn: 'https://www.linkedin.com/in/anudeep-sree-ramchetty-a7a53655/',
    team: 'Education Policy'
  },
  {
    id: 8,
    name: 'Rakesh',
    image: '../images/teamfifty/rakesh.jpeg',
    designation: 'xVA Risk Manager',
    company: 'ANZ',
    location: 'Singapore',
    linkedIn: 'https://www.linkedin.com/in/rakesh-thonukunoori-45b94241/',
    team: null
  },
  {
    id: 9,
    name: 'Raviteja',
    image: '../images/teamfifty/raviteja.jpeg',
    designation: 'Web Solutions Engineer',
    company: 'Google',
    location: 'Hyderabad, India',
    linkedIn: 'https://www.linkedin.com/in/raviteja-gollamudi-7a4a3914/',
    team: 'Website and Social Media'
  },
  {
    id: 10,
    name: 'Bhargav Teja',
    image: '../images/teamfifty/bhargav.jpeg',
    designation: 'Software Development',
    company: null,
    location: 'France',
    linkedIn: 'https://www.linkedin.com/in/btnallapu/',
    team: 'Education Policy'
  },
  {
    id: 11,
    name: 'Anil',
    image: '../images/teamfifty/anil.jpeg',
    designation: 'Building the next-gen retail company',
    company: 'BHARAT',
    location: 'Karnataka, India',
    linkedIn: 'https://www.linkedin.com/in/anilthontepu/',
    team: null
  },
  {
    id: 12,
    name: 'Raghavan R',
    image: '../images/teamfifty/raghavan.jpeg',
    designation: 'Data Science Enthusiast',
    company: null,
    location: 'California',
    linkedIn: 'https://www.linkedin.com/in/raghavanrajashekaran/',
    team: null
  },
  {
    id: 13,
    name: 'Srujan Reddy Challa',
    image: '../images/teamfifty/srujanchella.jpeg',
    designation: 'Software Engineer',
    company: 'Oracle',
    location: 'California, United States',
    linkedIn: 'https://www.linkedin.com/in/srujanreddychalla/',
    team: null
  },
  {
    id: 14,
    name: 'Krish',
    image: '../images/teamfifty/krish.jpeg',
    designation: 'Building AI-powered tools',
    company: 'Biotech Square',
    location: 'Toronto, Canada',
    linkedIn: 'https://www.linkedin.com/in/krish-perumal-20813334/',
    team: null
  },
  {
    id: 15,
    name: 'Tejaswini Poluri',
    image: '../images/teamfifty/tejaswini.jpeg',
    designation: 'Engineer',
    company: 'Qualcomm',
    location: 'Karnataka, India',
    linkedIn: 'https://www.linkedin.com/in/tejaswini-poluri-a8963416/',
    team: 'Curriculum Development'
  },
  {
    id: 16,
    name: 'Shanthi Swaroop',
    image: '../images/teamfifty/shantiswaroop.jpeg',
    designation: 'Software Engineer',
    company: 'Fairwinds',
    location: 'Gainesville, Florida',
    linkedIn: 'https://www.linkedin.com/in/shaswa/',
    team: 'Curriculum Development'
  },
  {
    id: 17,
    name: 'Mahendra Chaitanya',
    image: '../images/teamfifty/mahendra.jpg',
    designation: 'Inspector',
    company: 'Income Tax',
    location: 'India',
    linkedIn: 'https://www.linkedin.com/in/mahendra-chaitanya-586758167/',
    team: null
  },
  {
    id: 18,
    name: 'Venkata Vadrevu',
    image: '../images/teamfifty/venkata.jpeg',
    designation: 'Software Engineer',
    company: 'Cisco',
    location: 'San Francisco',
    linkedIn: 'https://www.linkedin.com/in/venkata-vadrevu/',
    team: null
  },
  {
    id: 19,
    name: 'Sailesh Thota',
    image: '../images/teamfifty/sailesh.jpeg',
    designation: 'Sr. Advisor, Product Marketing',
    company: 'Dell',
    location: 'Austin, Texas',
    linkedIn: 'https://www.linkedin.com/in/sailesh-thota/',
    team: 'App Development'
  },
  {
    id: 20,
    name: 'Karthik Bagade',
    image: '../images/teamfifty/karthik.jpeg',
    designation: 'R&D Engineer IC Design 3',
    company: 'Broadcom Inc.',
    location: 'California',
    linkedIn: 'https://www.linkedin.com/in/karthikbagade/',
    team: 'Education Policy'
  },
  {
    id: 21,
    name: 'Pavan Chand',
    image: '../images/teamfifty/pavan.jpeg',
    designation: 'Vice President Operations Yummykitchen',
    company: 'Yummy Corp',
    location: 'Indonesia',
    linkedIn: 'https://www.linkedin.com/in/pavanchand/',
    team: 'Curriculum Development'
  },
  {
    id: 22,
    name: 'Krishna Chaitanya',
    image: '../images/teamfifty/krishnas.jpg',
    designation: 'Data Analyst',
    company: 'Amazon',
    location: 'Seattle, Washington',
    linkedIn: 'https://www.linkedin.com/in/krishna-chaitanya-vamaraju-29a93649/',
    team: null
  },
  {
    id: 23,
    name: 'Satya Sandeep',
    image: '../images/teamfifty/satya.jpeg',
    designation: 'State Lead, Foundational Literacy and Numeracy',
    company: 'Central School Foundation',
    location: 'Hyderabad',
    linkedIn: 'https://www.linkedin.com/in/ksatyasandeep/',
    team: 'Curriculum Development'
  },
  {
    id: 24,
    name: 'Uday Kovur',
    image: '../images/teamfifty/uday.jpeg',
    designation: 'Data Scientist',
    company: ' Adidas',
    location: 'Bavaria, Germany',
    linkedIn: 'https://www.linkedin.com/in/uday-kovur-21337357/',
    team: null
  },
  {
    id: 25,
    name: 'Bhargav Golla',
    image: '../images/teamfifty/bhargavG.jpeg',
    designation: 'Software Engineer',
    company: ' LinkedIn',
    location: 'San Francisco',
    linkedIn: 'https://www.linkedin.com/in/bhargavgolla/',
    team: null
  },
  {
    id: 26,
    name: 'Udit Vashist',
    image: '../images/team/udit.jpg',
    designation: null,
    company: null,
    location: null,
    linkedIn: 'https://www.linkedin.com/in/udit-vashist-99b33829/',
    team: null
  },
  {
    id: 27,
    name: 'Akshik Tumma',
    image: '../images/teamfifty/ashik.jpeg',
    designation: 'Senior Associate',
    company: 'PwC Canada',
    location: 'Montreal, Canada',
    linkedIn: 'https://www.linkedin.com/in/akshik-tumma/',
    team: null
  },
  {
    id: 28,
    name: 'Varun Krovvidi',
    image: '../images/teamfifty/varunK.jpeg',
    designation: 'Product Marketing Manager',
    company: 'MuleSoft',
    location: 'San Francisco',
    linkedIn: 'https://www.linkedin.com/in/varunkrovvidi/',
    team: 'Management'
  },
  {
    id: 29,
    name: 'Vivekananda',
    image: '../images/teamfifty/vivekandanda.jpeg',
    designation: 'Member Of Technical Staff',
    company: 'Pensando Systems',
    location: 'California',
    linkedIn: 'https://www.linkedin.com/in/vivekanandavakkalanka/',
    team: 'Management'
  },
  {
    id: 30,
    name: 'Sameera Magapu',
    image: '../images/teamfifty/sameera.jpeg',
    designation: 'Software Development Engineer',
    company: 'Amazon Web Services',
    location: 'California, United States',
    linkedIn: 'https://www.linkedin.com/in/sameeramagapu/',
    team: null
  },
  {
    id: 31,
    name: 'Anvesh Uppalapati',
    image: '../images/teamfifty/anvesh.jpeg',
    designation: 'Software Development Engineer',
    company: 'Amazon',
    location: 'Greater Seattle Area',
    linkedIn: 'https://www.linkedin.com/in/udit-vashist-99b33829/',
    team: null
  },
  {
    id: 33,
    name: 'Chandrakanth',
    image: '../images/teamfifty/chndrakanth.jpeg',
    designation: 'Software Development Engineer',
    company: 'Zenefits',
    location: 'Karnataka, India',
    linkedIn: 'https://www.linkedin.com/in/vckanth/',
    team: 'App Development'
  },
  {
    id: 34,
    name: 'Anil Srinivas',
    image: '../images/teamfifty/anilS.jpg',
    designation: null,
    company: null,
    location: null,
    linkedIn: null,
    team: 'Child Educator Mentorship'
  },
  {
    id: 35,
    name: 'Sahithya Ramesh',
    image: '../images/teamfifty/sahithya.jpg',
    designation: null,
    company: null,
    location: null,
    linkedIn: null,
    team: null
  },
  {
    id: 36,
    name: 'Sonika Vanapalli',
    image: '../images/team/user.png',
    designation: 'Technical Lead',
    company: 'Yatra Online Pvt Ltd',
    location: 'Hyderabad',
    linkedIn: 'https://www.linkedin.com/in/sonika-vanapalli-4a03b199/',
    team: 'Curriculum Development'
  },
  {
    id: 37,
    name: 'Saketh K',
    image: '../images/teamfifty/saketh.jpeg',
    designation: 'Senior Data Engineer',
    company: 'Anthem Inc.',
    location: 'Orlando, Florida Area',
    linkedIn: 'https://www.linkedin.com/in/sakethk/',
    team: 'Management'
  },
  {
    id: 38,
    name: 'Raviteja Kondubhatla',
    image: '../images/teamfifty/raviteja k.jpeg',
    designation: 'Data Analyst',
    company: 'Mindtree (Microsoft)',
    location: 'Greater Seattle Area',
    linkedIn: 'https://www.linkedin.com/in/ravitejakondubhatla/',
    team: null
  },
  {
    id: 39,
    name: 'Venkata Naveen Bantu',
    image: '../images/teamfifty/naveen bantu.jpeg',
    designation: 'Development Engineer',
    company: 'Voith Digital Solutions',
    location: 'Germany',
    linkedIn: 'https://www.linkedin.com/in/venkata-naveen-bantu-2a2a6822/',
    team: 'Website and Social Media'
  },
  {
    id: 40,
    name: 'Harish Kairamkonda',
    image: '../images/teamfifty/harish.jpeg',
    designation: 'Assistant Vice President',
    company: 'Bank Of America',
    location: 'North Carolina',
    linkedIn: 'https://www.linkedin.com/in/harishkairamkonda/',
    team: null
  },
  {
    id: 41,
    name: 'Sindhur Dosapati',
    image: '../images/teamfifty/sindhur.jpeg',
    designation: 'Software Development',
    company: 'Amazon',
    location: 'Seattle, Washington',
    linkedIn: 'https://www.linkedin.com/in/sindhur-dosapati-2b875929/',
    team: null
  },
  {
    id: 42,
    name: 'Anurag Dusanapudi',
    image: '../images/teamfifty/anurag.jpeg',
    designation: 'Senior Advisor',
    company: 'Dell Technologies',
    location: 'Texas',
    linkedIn: 'https://www.linkedin.com/in/anuragdusanapudi/',
    team: 'Management'
  },
  {
    id: 43,
    name: 'Archana Kashikar',
    image: '../images/teamfifty/archana.jpeg',
    designation: 'Robotics Engineer',
    company: 'Arevo Inc.',
    location: 'Los Altos, California',
    linkedIn: 'https://www.linkedin.com/in/archanakashikar/',
    team: 'Curriculum Development'
  },
  {
    id: 44,
    name: 'Sudheer Babu Marisetty',
    image: '../images/teamfifty/sudheer.jpeg',
    designation: 'Engagement Manager',
    company: 'McKinsey & Company',
    location: 'India',
    linkedIn: 'https://www.linkedin.com/in/sudheermarisetty/',
    team: null
  },
  {
    id: 45,
    name: 'Vinila Sista',
    image: '../images/teamfifty/vinila.jpeg',
    designation: 'Data Science and Business Analytics Specialist',
    company: null,
    location: 'Greater Salt Lake City Area, Utah',
    linkedIn: 'https://www.linkedin.com/in/vinila-sista/',
    team: 'Education Policy'
  },
  {
    id: 46,
    name: 'Sujith Kumar Kupunarapu',
    image: '../images/teamfifty/sujith.jpeg',
    designation: 'Senior Software Engineer',
    company: 'CSX Technology',
    location: 'Jacksonville, Florida',
    linkedIn: 'https://www.linkedin.com/in/sujjjith',
    team: 'App Development'
  },
  {
    id: 47,
    name: 'Robindeep M.K.',
    image: '../images/teamfifty/Robindeep.jpeg',
    designation: 'Senior Consultant',
    company: 'Kline & Company',
    location: 'Hyderabad Area, India',
    linkedIn: 'https://www.linkedin.com/in/robindeep-m-k-7a437025?originalSubdomain=in',
    team: null
  },
  {
    id: 48,
    name: 'Avinash Paruchuri',
    image: '../images/teamfifty/avinash.jpeg',
    designation: 'Software Engineer',
    company: 'Google',
    location: 'San Francisco',
    linkedIn: 'https://www.linkedin.com/in/avinash-paruchuri-75035523/',
    team: null
  },
  {
    id: 49,
    name: 'Swarochisha Kandregula',
    image: '../images/teamfifty/swaroch.jpeg',
    designation: 'Solutions Specialist MEP',
    company: 'Microdesk',
    location: 'Fremont, California',
    linkedIn: 'https://www.linkedin.com/in/swarochisa/',
    team: 'Education Policy'
  },
  {
    id: 50,
    name: 'Ganesh Kumar Kattamuri',
    image: '../images/teamfifty/ganesh.jpeg',
    designation: 'Site Reliability Engineer',
    company: 'Slack',
    location: 'Pune, Maharashtra, India',
    linkedIn: 'https://www.linkedin.com/in/ganeshk254/?originalSubdomain=in',
    team: null
  },
  {
    id: 51,
    name: 'Anusha Ganta',
    image: '../images/teamfifty/anusha.jpeg',
    designation: 'Software Engineer-III',
    company: 'eBay',
    location: 'San Jose, California',
    linkedIn: 'https://www.linkedin.com/in/anushaganta/',
    team: null
  },
]
