import React from 'react'
import { Container } from 'reactstrap'
import { createUseStyles } from 'react-jss'
import { faInstagram, faTwitter, faFacebookF, faFlickr, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = createUseStyles({
  wrapper: {
    backgroundColor:  'green',
    bottom: 0,
    width: '100%'
  },
  FooterBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  socialIcons: {
    justifyContent: 'space-between',
    color: 'black',
    marginLeft: '2%'
  },
  iconSection: {
    textAlign: 'center',
    width: '100%'
  }
})

function Contact() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <Container className={classes.FooterBlock}>
          <div className={classes.iconSection}>
            <a href="https://www.facebook.com/santhustaFoundation" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className={classes.socialIcons} icon={faFacebookF} fixedWidth size="2x" />
            </a>
            <a href="https://www.instagram.com/santhustafoundation/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className={classes.socialIcons} icon={faInstagram} fixedWidth size="2x" />
            </a>
            <a href="https://twitter.com/santhusta" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className={classes.socialIcons} icon={faTwitter} fixedWidth size="2x" />
            </a>
            <a href="https://www.flickr.com/photos/144811320@N08/albums" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className={classes.socialIcons} icon={faFlickr} fixedWidth size="2x" />
            </a>
            <a href="https://www.youtube.com/channel/UCosR6q2CoyGoaCmEoQlZXIA" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className={classes.socialIcons} icon={faYoutube} fixedWidth size="2x" />
            </a>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Contact