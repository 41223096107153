import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Container, Collapse } from 'reactstrap'

import Text from 'components/typograpgy/Text'
// import Button from 'components/base/Button'
import Card from 'components/base/Card'
import stories from 'components/lists/stories'
import PaymentButtonSection from 'components/PaymentButtonSection'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left'
  },
  button: {
    textAlign: 'center'
  }
})

function ImpactPage() {
  const classes = useStyles()
  const [isHidden, setIsHidden] = useState(false)

  const impactstories = stories.map((story) => (
    <div id={story.id}>
      {console.log(story.id)}
      <Card details={story} isStory />
    </div>
  ))

  return (
    <Container>
      <PaymentButtonSection text="Just 36% grade 1 children in Rajasthan read letters, only 43% recognize numbers 1-9. Let’s change that"/>
      <Text size="xxlarge" bold align="center" density="normal"> Impact Stories </Text>
      <div className={classes.container}>
        {impactstories}
      </div>
      {/* <div className={classes.button}>
        <Button
          label={isHidden ? 'Hide stories' : 'Read more stories'}
          onClick={() => setIsHidden(!isHidden)}
        />
      </div> */}
      <Collapse isOpen={isHidden}>
        {/* {moreStories} */}
      </Collapse>
    </Container>
  )
}

export default ImpactPage