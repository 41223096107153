import React from 'react'
import { createUseStyles } from 'react-jss'

import Text from 'components/typograpgy/Text'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginBottom: '5%'
  },
  image: {
    '&:hover': {
      opacity: 0.5,
      color: '#000',
    }
  }
})

function Icon({ items }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {items.map((item) => 
        <React.Fragment>
          <a href={item.link} target="_blank" rel="noopener noreferrer" >
            <img
              className={classes.image}
              src={item.src}
              alt={item.altText}
              style={{
                display: 'block',
                width: 'auto',
                height: 200,
                borderRadius: '1%',
                cursor: 'pointer',
                border: '1px solid #6c757d'
              }}
            />
            <Text bold size="xlarge" textColor="#000">{item.name}</Text>
          </a>
        </React.Fragment>
      )
      }
    </div >
  )
}


export default Icon
