import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  logo: {
    textAlign: 'center'
  }
})

function Logo() {
  const classes = useStyles()
  return (
    <div className={classes.logo}>
      <img
        src="../images/logo.png"
        alt="logo"
        style={{
          height: 50,
          width: 140
        }}
      />
    </div>
  )
}

export default Logo
