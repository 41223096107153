import React from 'react'
import { Container } from 'reactstrap'
import { createUseStyles } from 'react-jss'
import { faEnvelope, faPhone, faMapMarked } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faTwitter, faFacebookF, faFlickr, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Spacer from 'components/base/Spacer'
import Text from 'components/typograpgy/Text'
import PaymentButtonSection from 'components/PaymentButtonSection'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  contactDetails: {
    width: 640
  },
  seperator: {
    borderLeft: '1px solid green',
    borderRight: '1px solid green',
    height: 968,
    width: 4
  },
  icon: {
    color: 'green'
  },
  details: {
    flexDirection: 'column',
    width: 640,
    alignSelf: 'center'
  },
  orgDetails: {
    display: 'flex',
    alignItems: 'center'
  },
  line: {
    borderLeft: '2px solid green',
    height: 'auto',
    marginLeft: 30
  },
  divider: {
    borderTop: '2px solid green',
    margin: 30
  },
  socialIcons: {
    justifyContent: 'space-between',
    color: 'green'
  },
  iconSection: {
    textAlign: 'center'
  }
})

function Contact() {
  const classes = useStyles()
  const isSmallScreen = window.innerWidth <= 768

  return (
    <>
      <PaymentButtonSection text="Lifetime cost of growth deficit in early childhood is 154% in terms of % loss of annual adult wage"/>
      <Container fluid>
        <Text size="headline" bold align="center" density="normal"> Contact Us </Text>
        <div className={classes.container}>
          <div className={classes.form}>
            {isSmallScreen &&
              <iframe title="contact-form" src="https://docs.google.com/forms/d/e/1FAIpQLSd5icweCZ8h9JRbZWAu2zg5gaH3mNoLF5YD5wXZqhNFwL7wDA/viewform?embedded=true" width="340" height="980" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            }
            {!isSmallScreen &&
              <iframe title="contact-form" src="https://docs.google.com/forms/d/e/1FAIpQLSd5icweCZ8h9JRbZWAu2zg5gaH3mNoLF5YD5wXZqhNFwL7wDA/viewform?embedded=true" width="640" height="980" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            }
          </div>
          {!isSmallScreen && <div className={classes.seperator} />}
          <div className={classes.details}>
            <p className={classes.orgDetails}>
              <FontAwesomeIcon className={classes.icon} icon={faMapMarked} fixedWidth size="3x" />
              <div className={classes.line}>
                <Text size="medium" align="left"> Santhusta Foundation, First Floor, SB-2, Opp. JDA Office, Bapu Nagar, Jaipur - 302004 </Text>
              </div>
            </p>
            <Spacer height={50} />
            <p className={classes.orgDetails}>
              <FontAwesomeIcon className={classes.icon} icon={faEnvelope} fixedWidth size="3x" />
              <div className={classes.line}>
                <Text size="medium" align="left"> mail@santhusta.org </Text>
              </div>
            </p>
            <Spacer height={50} />
            <p className={classes.orgDetails}>
              <FontAwesomeIcon className={classes.icon} icon={faPhone} fixedWidth size="3x" />
              <div className={classes.line}>
                <Text size="medium" align="left">+91 894 798 6351 </Text>
              </div>
            </p>
            <p className={classes.divider} />
            <p className={classes.iconSection}>
              <a href="https://www.facebook.com/santhustaFoundation" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className={classes.socialIcons} icon={faFacebookF} fixedWidth size="2x" />
              </a>
              <a href="https://www.instagram.com/santhustafoundation/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className={classes.socialIcons} icon={faInstagram} fixedWidth size="2x" />
              </a>
              <a href="https://twitter.com/santhusta" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className={classes.socialIcons} icon={faTwitter} fixedWidth size="2x" />
              </a>
              <a href="https://www.flickr.com/photos/144811320@N08/albums" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className={classes.socialIcons} icon={faFlickr} fixedWidth size="2x" />
              </a>
              <a href="https://www.youtube.com/channel/UCosR6q2CoyGoaCmEoQlZXIA" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className={classes.socialIcons} icon={faYoutube} fixedWidth size="2x" />
              </a>
            </p>
            <Text>	&copy; www.santhusta.org</Text>
          </div>
        </div>
        <div className={classes.divider} />
      </Container>
    </>
  )
}

export default Contact