/* eslint-disable max-len */
export default [
  {
    id: 0,
    name: 'Udit Vashist',
    image: '../images/team/udit.jpg',
    designation: 'Co-founder, CEO',
    about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
    linkedIn: 'https://www.linkedin.com/',
    about: 'Udit is responsible for overseeing all internal and external operations at Santhusta and has been a key contributor to setting the organisations strategic direction with the Centers of excellence program as well to areas of fundraising and development of our tech solution Shiksha Ki Ore. He joined Santhusta heading the Product and Partnership verticals before assuming the role of CEO. Udit has a rich experience in building capacity and scaling social initiatives and CSRs across sectors of Education, healthcare, gender empowerment and livelihoods. Previously he worked with the Mumbai based Philanthropic foundation Dasra and also had a stint at McKinsey and Company. Udit holds a Bachelors of Science degree in Statistics with honours from Sri Venkateswara college, Delhi University. He enjoys writing, sports, trekking and is a former pro table tennis player.'
  },
  {
    id: 2,
    name: 'Shubham Purohit',
    image: '../images/team/Shubham.jpg',
    designation: 'Co-founder, COO',
    about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
    linkedIn: 'https://www.linkedin.com/in/shubham-purohit-ba28b7179',
    about: 'Shubham has been working with us for about 3 years now. He started off working on the ground in the RTE program and helped us in data management and program execution.He played a crucial role in the School Readiness program where he worked closely in underprivileged communities. In recent times, being the program manager, he is overseeing engagement with government officials, functions of finance, and social media. Previously he spent 3 years in the business processing sector as a team lead. He did his schooling from Pilani and further education from Jaipur. In his spare time he likes to hike and also plays soccer.'
  },
  {
    id: 1,
    name: 'Shipra Gaur',
    image: '../images/team/shipraji.jpeg',
    designation: 'Senior Associate, Teacher Education',
    about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
    linkedIn: 'https://www.linkedin.com/in/shipra-sharma-352764201',
    about: 'Shipra is helping us with content development and also provides training sessions for our educators. She prepared videos for Shiksha Ki Ore which are also available on Youtube. She is a well trained teacher having experience of 16 years and has a diploma in Nursery Teacher Training course. She completed her education from Awadh University. In Centres of Excellence, she is overseeing the assessments, worksheets and test papers helping our educators in the process of Early Childhood Education. She likes to draw and travel in her spare time.'
  },
  {
    id: 3,
    name: 'Arjun Singh',
    image: '../images/team/arjun.jpg',
    designation: 'Associate, Programs ',
    about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
    linkedIn: 'https://www.linkedin.com/in/arjun-rathore-27249a201',
    about: 'Arjun has been a part of our team for 2 years now. He began his journey at Santhusta by working in and between local communities where we ran our programs like School readiness and Shiksha Ki Ore. He also conducted training sessions to the anganwadi workers for the Shiksha Ki Ore mobile application. He helps us with most of the groundwork and has made good relations with our stakeholders. At present, he is monitoring and providing support visits and supplies to our educators in the Centres Of Excellence program. Previously he worked in banking and finance sector, He has completed his education from Pilani and aspires to continue working in the development sector. He likes to play badminton and watch movies in his spare time.'
  }
]
