import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(({ colors, units }) => ({
    hr: {
      display: 'block',
      marginTop: 0.5,
      marginBottom: 0.5,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderStyle: 'inset',
      borderWidth: 1,
      width: '80%'
    }
}))

function Divider({ flexGrow, variant }) {
    const classes = useStyles({ flexGrow, variant })

    return <div className={classes.hr} />
}

Divider.propTypes = {
    flexGrow: PropTypes.number,
}

Divider.defaultProps = {
    flexGrow: undefined,
}

export default Divider