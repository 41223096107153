/* eslint-disable max-len */
export default [
  {
    id: 3,
    image: '../images/children1.png',
    title: 'Akshay and Preeti',
    date: 'Children',
    description: 'Meet Akshay and his sister Preeti, the brightest of all. Due to an illness, their father lost his job which made their mother to work. There was no one for their care and educational support. Santhusta Foundation came in contact and arranged everyday support visits for the curriculum and then they were able to easily perform activities and tasks from the books.'
  },
  {
    id: 4,
    image: '../images/children2.png',
    title: 'Sandes',
    date: 'Children',
    description: 'Every child has the right to education by which they can dream of becoming the person they desire. Here\'s the story of Sandes, a child whose father is an auto rickshaw driver and was concerned about his child\'s future. When asked, Sandes said he wants to become a doctor. We engaged them with Santhusta\'s mobile learning app Shiksha Ki Ore. Now Sandes is able to write letters, numbers and even recognise colours and shapes.'
  },
  {
    id: 6,
    video: "https://drive.google.com/file/d/1DRUsITf9bVz4WIKwcu4C0hyGHaq0yVvH/preview",
    title: 'Priyanshi',
    date: 'Children',
    description: 'Here is Priyanshi\'s journey with Santhusta and how she utilized her time in lockdown.'
  },
  {
    id: 1,
    image: '../images/educator2.jpeg',
    title: 'Seema Devi',
    date: 'Anganwadi Educator',
    description: 'Meet Seema ji, one of our educators from Valmiki Basti. She shared her experience while working with Santhusta\'s Shiksha Ki Ore app. She was easily able to pick up with using the application and teaches her her daughter and other anganwadi children as well.'
  },
  {
    id: 0,
    image: '../images/educator1.png',
    title: 'Ritu Kanwar',
    date: 'Anganwadi Educator',
    description: 'This is Ritu Kanwar, an anganwadi worker from Tilak Nagar kacchi basti. Santhusta approached her with the Shiksha Ki Ore app, though she was hesitant at the beginning but afterwards she acknowledged the usefulness of the app and now is comfortable using it to teach her daughter and other anganwadi children while maintaining social distance. She also advised to add more features in the app.'
  },
  {
    id: 2,
    image: '../images/parent4.png',
    title: 'Sonu Devi',
    date: 'Parent',
    description: 'Meet Sonu Devi, one of our educators who was worried for her daughter\'s education as the anganwadis were closed due to the pandemic. She started using Shiksha ki Ore application and not only did she teach her own child but also some other children of that area.'
  },
  {
    id: 5,
    video: "https://drive.google.com/file/d/1PCMpMduld7c0KPkPGnscecQcWcZco9R2/preview",
    title: 'Shubham Purohit',
    date: 'Staff',
    description: 'Shubham has been with Santhusta for over two years now. Here he is sharing his experience and learnings so far.'
  }
]
