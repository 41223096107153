import React, { Fragment } from 'react'
import Carousel from 'components/Carousel'
import { Container } from 'reactstrap'


import Text from 'components/typograpgy/Text'
import Spacer from 'components/base/Spacer'
import PaymentButtonSection from 'components/PaymentButtonSection'

function About() {
  const items = [
    {
      src: '../images/slideAboutus1.jpeg',
      altText: 'Slide 1',
      caption: null,
      link: null
    },
    {
      src: '../images/slideAboutus2.jpeg',
      altText: 'Slide 2',
      caption: null,
      link: null
    },
    {
      src: '../images/slideAboutus3.jpeg',
      altText: 'Slide 3',
      caption: null,
      link: null
    }
  ]

  return (
    <Fragment>
      <PaymentButtonSection text="Help change the lives of 100,000 children"/>
      <Carousel items={items} />
      <Container>
        <Spacer height={30} />
        <Text bold size="large" align="left" isSubHeader>WHO ARE WE</Text>
        <Spacer height={10} />
        <Text align="left">Santhusta Foundation was founded as a partner organization of Indus Action Initiatives, a non profit working to provide access of legislated rights to the underserved communities.</Text>
        <Text align="left">Santhusta Foundation and  Indus Action worked jointly on programs such as RTE 12(1)c and School Readiness Program, from 2018 - 2020 in the state of Rajasthan, with Jaipur as the headquarters of operation. </Text>
        <Text align="left">Post the onset of Covid-19, Santhusta increased focus on early childhood education working with Anganwadi teachers and parents of children between ages 3 to 6 yrs in the poor communities of Jaipur. Through its Shiksha Ki Ore program, 100+ Anganwadi teachers are being trained on a day to day basis, while the Centers of Excellence program aims to develop 10 model early childhood education centers. </Text>
        <Text align="left">Udit Vashist is presently the CEO of Santhusta Foundation and is supported by a young and energetic team on ground to take the programs and impact of the foundation to the next level.</Text>
        <Text align="left">Santhusta is supported by Team 50, a group of individuals who contribute to the cause of early childhood education with their time and financial support. </Text>
        <Spacer height={20} />
        <Text bold align="left" size="large" isSubHeader>Vision</Text>
        <Text align="left">A happy and joyful childhood for all.</Text>
        <Spacer height={20} />
        <Text bold align="left" size="large" isSubHeader>Mission</Text>
        <Text align="left" size="xsmall" density="normal">
            &emsp;&bull;&emsp; To provide facilities needed for holistic child development.<br/>
            &emsp;&bull;&emsp; To ensure a child gets quality learning, nutrition and good health.<br/>
            &emsp;&bull;&emsp; To facilitate the growth of a child's natural abilities.<br/>
          </Text>
        <Spacer height={20} />
        <Text bold size="large" align="left" isSubHeader>CORE VALUES / PRINCIPLES</Text>
        <Spacer height={10} />
        <Text align="left" size="medium" bold>Compassion</Text>
        <Text align="left">We understand what our stakeholders, be that the communities we serve or the partners we work with, need and expect; we will always do our best to make sure that they get the empathy and respect when engaging with us.</Text>
        <Text align="left" size="medium" bold>Excellence</Text>
        <Text align="left">We pursue the highest standards in all that we do; we continually learn and innovate for better outcomes.</Text>
        <Text align="left" size="medium" bold>Humility</Text>
        <Text align="left">We know that our work is not possible without the support of our stakeholders; we are truly grateful for their commitment to our vision of a happy childhood for every child.</Text>
        <Text align="left" size="medium" bold>Integrity</Text>
        <Text align="left">We do the right thing, always.</Text>
      </Container>
    </Fragment>
  )
}

export default About