import React from 'react'
import { Container } from 'reactstrap'
import { createUseStyles } from 'react-jss'
import Carousel from 'components/Carousel'

import Text from 'components/typograpgy/Text'
import Spacer from 'components/base/Spacer'
import Icons from 'components/icon'
import ImageText from 'components/ImageText'
import InfographicText from 'components/InfographicText'
import PaymentButtonSection from 'components/PaymentButtonSection'

const useStyles = createUseStyles({
  wrapper: {
    display:({isSmallScreen}) => isSmallScreen ? '' : 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems:'flex-start'
  }
})

function Home() {
  const isSmallScreen = window.innerWidth <= 768
  const classes = useStyles({isSmallScreen})
  // var donate =require('./../pages/donate.html');

  const items = [
    {
      src: '../images/slide3Home.jpeg',
      link: '#UNESCO'
    },
    {
      src: '../images/slide1Home.png',
      // link: '/project#ECE-Stats'
    },
    {
      src: '../images/slide2Home.jpeg',
      // link: '/project#TheIssue'
    }
  ]

  const partnerLogos = [
    // {
    //   src:'../images/partner2.jpeg',
    //   link:'https://www.aubank.in/',
    //   altText: 'partner1',
    //   name: 'AU Small Finance Bank'
    // },
    {
      src:'../images/partner1.jpeg',
      link:'https://www.indusaction.org/',
      altText: 'partner2',
      name: 'Indus Action'
    },
    {
      src:'../images/Project_Nirmaan_Logo.png',
      link: 'https://nirmaan.org/',
      altText: 'Project_Nirmaan_Logo',
      name: 'Project Nirmaan'
    }
  ]

  const ImpactData = [
    {
      text: 'More likely to drop out of school',
      number: '25%'
    },
    {
      text: 'More likely to be placed in special education',
      number: '50%'
    },
    {
      text: 'More likely to never attend college',
      number: '60%'
    },
    {
      text: 'More likely to be arrested for a violent crime',
      number: '70%'
    }
  ]

  const data = ImpactData.map((data) => (
    <ImageText number={data.number} text={data.text} color="#ff4141"/>
  ))

  return (
    <React.Fragment>
      <PaymentButtonSection text="Let’s put back smiles on children’s faces"/>
      <Carousel items={items} />
      <Container>
        <Spacer height={30} />
        <Text align="center" bold size="xxlarge">Risks of not investing properly in Early Childhood Development</Text>
        <Spacer height={30} />
        <div className={classes.wrapper}>
          {data}
        </div>
        <Spacer height={30} />
      </Container>
        <InfographicText />
      <Container>
        <hr />
        <Text align="center" bold size="xxlarge">Present & Past Partners</Text>
        <hr />
        <Spacer height={30} />
        <Icons items={partnerLogos} />
      </Container>
    </React.Fragment>
  )
}

export default Home