import React from 'react'
import { createUseStyles } from 'react-jss'

import Text from 'components/typograpgy/Text'
import PaymentButton from "components/PaymentButton"

const useStyles = createUseStyles({
  paymentButton: {
    margin: 10
  },
  verticalLine: {
    borderLeft: '1px solid #fff',
    height: 40,
    margin: 10
  },
  horizontalLine: {
    border: '1px solid #fff',
    margin: 10,
    width: 40
  },
  donateSection: {
    display: 'flex',
    flexDirection: ({isSmallScreen}) => isSmallScreen ? 'column' : 'row',
    backgroundColor: "#5b7ebd",
    justifyContent: 'center',
    alignItems: 'center'
  }
})

function PaymentButtonSection({ text }) {
  const isSmallScreen = window.innerWidth <= 768
  const classes = useStyles({isSmallScreen})

  return (
    <React.Fragment>
      <div className={classes.donateSection}>
        <Text align="center" textColor="#fff">{text}</Text>
        <div className={isSmallScreen ? classes.horizontalLine : classes.verticalLine} />
        <div className={classes.paymentButton}>
          <PaymentButton payment_button_id="pl_FrXNdPZGSzjARc" />
        </div>
      </div>
    </React.Fragment>
  )
}

export default PaymentButtonSection