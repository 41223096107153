import React from 'react'
import { createUseStyles } from 'react-jss'
import { Container } from 'reactstrap'

import Text from 'components/typograpgy/Text'
import Spacer from 'components/base/Spacer'

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    paddingTop: ({isSmallScreen}) => isSmallScreen ? 15 : 30,
    paddingBottom: 15
  },
  bottomRight: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 18
  },
  image: {
    width: '40%',
    height: 'auto',
    opacity: '0.3',
  },
  textColumns: {
    display: ({ isSmallScreen }) => isSmallScreen ? '' :'flex'
  },
  singleTextBlock: {
    width: ({isSmallScreen}) => isSmallScreen ? '100%' : '50%'
  },
  parallax: {
    backgroundSize: 'cover',
    backgroundAttachment: 'scroll',
    backgroundPosition: 'center center',
    backgroundImage: `url(${'../images/humamBrain.jpg'})`,
    backgroundColor: 'RGBA(0, 0, 0, 0.62)',
    backgroundBlendMode: 'multiply',
    minHeight: 434,
    innnerPadding: 200
}
})

function InfographicText() {
  const isSmallScreen = window.innerWidth <= 768
  const classes = useStyles({isSmallScreen})

  return (
    <section className={classes.parallax}>
      <Container>
      <div className={classes.container} id="UNESCO">
        <Text size="xlarge" textColor="#fff">Key elements of Early Childhood Developement</Text>
        <Spacer height={10} />
          <div className={classes.textColumns}>
            <div className={classes.singleTextBlock}>
              <Text size="large" bold align="justify" density="tiny" textColor="#fff">SAFETY AND PROTECTION</Text>
              <Text align="justify" textColor="#fff">Protecting young children from violence and abuse is not only a human rights obligation; it is also the foundation from which children can develop to their fullest potential and achieve better health, learning and social development outcomes. Through a combination of ECD and child protection intervention, young children can be protected from violence and given the opportunity to develop and grow in a healthy way, from the very first years of life.</Text>
            </div>
            <div className={classes.singleTextBlock}>
              <Text size="large" bold align="justify" density="tiny" textColor="#fff">HEALTH AND NUTRITION</Text>
              <Text align="justify"textColor="#fff">Adequate nutrition is necessary for normal brain development, laying the foundation for the development of cognitive, motor and socio-emotional skills throughout childhood and adulthood. lack of adequate nutrition  and other related consequences such as stunting and low birthweight can compromise children’s motor and cognitive development. Children with restricted development of these skills during early life are at risk for later neuropsychological problems, poor school achievement, early school drop-out, low-skilled employment, and poor care of their own children, thus contributing to the intergenerational transmission of poverty</Text>
            </div>
          </div>
          <div className={classes.textColumns}>
            <div className={classes.singleTextBlock}>
              <Text size="large" bold align="justify" density="tiny" textColor="#fff">EARLY CHILDHOOD EDUCATION </Text>
              <Text align="justify" textColor="#fff">Investment in good quality early childhood education services prior to entering school improves learning outcomes for children. It also enhances the efficiency of the school system by reducing repetition and drop-out and improving achievement, especially among girls and marginalized groups.</Text>
            </div>
            <div className={classes.singleTextBlock}>
              <Text size="large" bold align="justify" density="tiny" textColor="#fff">STIMULATION AND CARE</Text>
              <Text align="justify" textColor="#fff">Early stimulation and interaction with parents and caregivers jumpstart brain development and promote well-being. Extensive research shows that nurturing, stimulating interaction between young children and their parents and caregivers positively and permanently strengthens the ability to learn – and may even change brain function for life.</Text>
            </div>
          </div>
        </div>
        <Spacer height={20} />
      </Container>
    </section>
  )
}

export default InfographicText
