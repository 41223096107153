import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

const fontSize = {
  headline: 40,
  xxlarge: 30,
  xlarge: 24,
  large: 20,
  semiLarge: 18,
  medium: 16,
  small: 14,
  xsmall: 13,
  tiny: 12,
  extraTiny: 11,
  extraSmall: 10
}

const lineHeight = {
  tiny: 0.8,
  compact: 1.2,
  cozy: 1.6,
  normal: 1.8
}

const useStyles = createUseStyles({
  heading: {
    textAlign: ({ align }) => align,
    fontSize: ({ size }) => fontSize[size],
    fontWeight: ({ bold }) => (bold ? 450 : 'normal'),
    lineHeight: ({ density }) => lineHeight[density],
    fontFamily: 'poppins',
    margin: '10px',
    color: ({textColor}) => textColor
  },
  horizontalLine: {
    borderBottom: '3px solid green',
    width: '10%',
    marginLeft: '1%'
  },
})


function Text({
  children,
  align,
  bold,
  textColor,
  density,
  semiTransparent,
  size,
  isSubHeader
}) {
  const classes = useStyles({
    align,
    bold,
    textColor,
    density,
    semiTransparent,
    size
  })

  return (
    <Fragment>
      <p className={classes.heading}>{children}</p>
      {isSubHeader && <p className={classes.horizontalLine}></p>}
    </Fragment>
  )
}

Text.propTypes = {
  align: PropTypes.oneOf(['center', 'justify', 'inherit', 'left', 'right']),
  bold: PropTypes.bool,
  // color: PropTypes.oneOf(['dark', 'light', 'muted', 'negative', 'primary', 'success']),
  textColor: PropTypes.string,
  density: PropTypes.oneOf(['compact', 'cozy', 'normal', 'tiny']),
  size: PropTypes.oneOf(Object.keys(fontSize)),
  isSubHeader: PropTypes.bool

}

Text.defaultProps = {
  align: 'center',
  bold: false,
  color: 'dark',
  density: 'compact',
  semiTransparent: false,
  size: 'small',
  isSubHeader: false,
  textColor: null
}

export default Text
